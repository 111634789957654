import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HtmlRenderer from "../../components/htmlRenderer"
import IconList from "../../components/iconList"
import BasicCard from "../../components/basicCard"

const WhyCloud = (props) => {
	const data = useStaticQuery(graphql`
		query WhyCloud {
			dnhCms {
				subPages(where: {pageTitle: "Why Cloud"}) {
					id
					bannerHeading
					pageTitle
					pageDescription
					heading
					richCopy{
						html
					}
					pageBanner {
						url
					}
					infoCards {
						id
						heading
						copy
						icon {
							url
						}
					}
					basicCards{
						id
						heading
						copy{
							html
						}
					}
				}
			}
		}
    `)
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.subPages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}
					bannerHeading={data.dnhCms.subPages[0].bannerHeading}
					bannerUrl={data.dnhCms.subPages[0].pageBanner.url}>
				<SEO title={data.dnhCms.subPages[0].pageTitle}
					description={data.dnhCms.subPages[0].pageDescription} />
				<div className="container w820">
					<HtmlRenderer
						htmlData={data.dnhCms.subPages[0].richCopy.html}
						rendererID="js-why-cloud-renderer"
						rendererClass="css-renderer padding-lr-16"/>
				</div>
				<BasicCard cardData={data.dnhCms.subPages[0].basicCards}
							container={true}
							itemClass="white-bg shadow"/>
				<IconList listData={data.dnhCms.subPages[0].infoCards} />
			</Layout>
		</>
	)
}

WhyCloud.propTypes = {
	location: PropTypes.object
}

export default WhyCloud
