import React from "react"
import PropTypes from "prop-types"
import HtmlRenderer from "./htmlRenderer"

const BasicCard = ({cardData, itemClass, container, subHeadingList}) => {

	const mapSubHeading = (subHeading, subHeadingList) => {
		let headingArray;

		if(subHeadingList){
			headingArray = subHeading.split(",");
		}
		return(
			<>
				{subHeadingList ? headingArray.map((item, index) => (
					<span key={index}>{item}</span>
				)) : <p>{subHeading}</p>}
			</>
		)
	}

	const itemWrap = (item, index, itemClass, subHeadingList) => {
		return(
			<div key={item.id} className={`basic-card-item-wrap ${itemClass ? itemClass : ''}`}>
				<div className="basic-card-item">
					<header>
						<h3>{item.heading}</h3>
						{item.subHeading && mapSubHeading(item.subHeading, subHeadingList)}
					</header>
					<HtmlRenderer
						htmlData={item.copy.html}
						rendererID={`js-basic-card-renderer-${index}`}
						rendererClass="css-renderer" />
				</div>
			</div>
		)
	}

	return(
		<div className="basic-card-wrap">
			{container ?
				<div className="container">
					{cardData.map((item, index) => (
						itemWrap(item, index, itemClass, subHeadingList)
					))}
				</div>
				:
				cardData.map((item, index) => (
					itemWrap(item, index, itemClass, subHeadingList)
				))
			}
		</div>
	)
}
BasicCard.defaultProps = {
	container: false,
	subHeadingList: false,
};
BasicCard.propTypes = {
	cardData: PropTypes.array.isRequired,
	itemClass: PropTypes.string,
	container: PropTypes.bool.isRequired,
	subHeadingList: PropTypes.bool.isRequired,
}

export default BasicCard
