import React from "react"

const IconList = (listData) => (
	<div className="icon-list-wrap container">
		{listData.listData.map(item => (
			<div key={item.id} className="icon-list-item">
				{/*
				<div className="icon-list-item__icon">
					<img src={item.icon.url} />
				</div>
				*/}
				<div className="icon-list-item__content">
					<div className="icon-list-item__heading-wrap">
						<img src={item.icon.url} />
						<h3>{item.heading}</h3>
					</div>
					<p>{item.copy}</p>
				</div>
			</div>
		))}
	</div>
)

export default IconList
